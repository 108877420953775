.carousel-and-text {
  display: flex;
  gap: 32px;
  align-items: center;
  margin-top: 32px;
}

.carousel-text-container {
  width: 448px;
}

.text {
  color: var(--default-opaque-neutrals-gray-80, #4A4A4A);
  font-size: 18px;
  font-weight: 300;
  line-height: 150%;
  max-width: 430px;

  p {
    margin-top: 16px;
    text-align: left;

    &:first-of-type {
      margin-top: 24px;
    }
  }
}

.title {
  margin-top: 24px;
  display: block;
  width: 100%;
}

.image {
  box-sizing: border-box;
  max-width: 354px;
  width: 100%;
  height: 365px;
  border-radius: 12px;
  background: #F8F5FF;
  display: flex;

  &>* {
    margin: auto;
    width: auto;
    max-width: 314px;
    max-height: 301px;
    font-size: 48px;
    text-wrap: wrap;
    text-align: center;
  }
}

.slide {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 32px;
}

@media screen and (max-width: 1060px) {
  .slide {
    flex-direction: column;
  }

  .text p, .title {
    text-align: center!important;
  }
}

@media screen and (max-width: 500px) {
  .image div {
    font-size: 36px;
  }
}